import React from 'react';
import BottomNav from '../components/BottomNav';
import Header from '../components/Header';

const TermsAndConditions = () => {
    return (
        <div className="page-wrapper max-w-[480px] mx-auto pb-8 bg-yellow-100">
            <Header />
            <div className="page-container">
                <div className="terms-container">
                    <h1 className="text-2xl font-bold mb-4">Terms & Conditions</h1>
                    <p className="text-sm text-gray-600 mb-4"><strong>Last updated on 31-12-2024 18:32:30</strong></p>
                    <p className="mb-4">
                        These Terms and Conditions, along with privacy policy or other terms (“Terms”) constitute a binding
                        agreement by and between MAHIPAL CHOUDHARY, (“Website Owner” or “we” or “us” or “our”) and you (“you” or “your”)
                        and relate to your use of our website, goods (as applicable) or services (as applicable) (collectively, “Services”).
                    </p>

                    <p className="mb-4">
                        By using our website and availing the Services, you agree that you have read and accepted these Terms
                        (including the Privacy Policy). We reserve the right to modify these Terms at any time and without
                        assigning any reason. It is your responsibility to periodically review these Terms to stay informed of updates.
                    </p>

                    <h2 className="text-xl font-semibold mb-2">Terms of Use</h2>
                    <ul className="list-disc list-inside space-y-2 mb-6">
                        <li>
                            To access and use the Services, you agree to provide true, accurate and complete information to us
                            during and after registration, and you shall be responsible for all acts done through the use of your
                            registered account.
                        </li>
                        <li>
                            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
                            performance, completeness or suitability of the information and materials offered on this website
                            or through the Services, for any specific purpose.
                        </li>
                        <li>
                            Your use of our Services and the website is solely at your own risk and discretion. You are
                            required to independently assess and ensure that the Services meet your requirements.
                        </li>
                        <li>
                            The contents of the Website and the Services are proprietary to Us and you will not have any
                            authority to claim any intellectual property rights, title, or interest in its contents.
                        </li>
                        <li>
                            You acknowledge that unauthorized use of the Website or the Services may lead to action against
                            you as per these Terms or applicable laws.
                        </li>
                        <li>
                            You agree to pay us the charges associated with availing the Services.
                        </li>
                        <li>
                            You agree not to use the website and/or Services for any purpose that is unlawful, illegal or
                            forbidden by these Terms, or Indian or local laws that might apply to you.
                        </li>
                        <li>
                            You agree and acknowledge that website and the Services may contain links to other third party
                            websites. On accessing these links, you will be governed by the terms of use, privacy policy and
                            such other policies of such third party websites.
                        </li>
                        <li>
                            You understand that upon initiating a transaction for availing the Services you are entering into a
                            legally binding and enforceable contract with us for the Services.
                        </li>
                        <li>
                            You shall be entitled to claim a refund of the payment made by you in case we are not able to
                            provide the Service. The timelines for such return and refund will be according to the specific
                            Service you have availed or within the time period provided in our policies (as applicable).
                        </li>
                        <li>
                            Notwithstanding anything contained in these Terms, the parties shall not be liable for any failure to
                            perform an obligation under these Terms if performance is prevented or delayed by a force majeure
                            event.
                        </li>
                    </ul>

                    <h2 className="text-xl font-semibold mb-2">Governing Law</h2>
                    <p className="mb-4">
                        These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and
                        construed in accordance with the laws of India.
                    </p>

                    <h2 className="text-xl font-semibold mb-2">Jurisdiction</h2>
                    <p className="mb-4">
                        All disputes arising out of or in connection with these Terms shall be subject to the exclusive
                        jurisdiction of the courts in BARMER, Rajasthan.
                    </p>

                    <h2 className="text-xl font-semibold mb-2">Contact Information</h2>
                    <p className="mb-4">
                        All concerns or communications relating to these Terms must be communicated to us using the
                        contact information provided on this website.
                    </p>
                </div>
            </div>
            <BottomNav />
        </div>
    );
};

export default TermsAndConditions;
